import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { SgwRequestActions } from '../../../store/actions';
import { getPhaseCostQuarterTableValues } from '../../../store/selectors/sgw';
import { COLORS } from '../../../theme';
import { translate, translateHtml } from '../../../translations/translate';
import { TRouterWithId, WorkType } from '../../../types';
import { Visible } from '../../common/layout/Visible.component';

const useStyles = createUseStyles({
  costTableCell: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  table: {
    width: '100%',
    '& th, & td': {
      font: 'inherit',
      fontFamily: 'inherit',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '10px',
    },
    '& table': {
      marginTop: '20px',
      marginBottom: '20px',
    },
    '& th': {
      fontWeight: 'bold',
    },
  },
  retributionInfo: {
    padding: '24px',
    marginBottom: '30px',
  },
  item: {
    color: COLORS.borderGrey,
    fontSize: 'larger',
    fontWeight: 'bold',
  },
  value: {
    fontSize: 'larger',
    fontWeight: 'bold',
  },
  retributionInfoText: {
    textAlign: 'justify',
  },
});

interface IProps {
  workType?: WorkType;
}

export const RetributionInfo: FC<IProps> = ({ workType }) => {
  const dispatch = useDispatch();
  const C = useStyles();
  const { id } = useParams<keyof TRouterWithId>();
  const phaseCostQuarters = useSelector(getPhaseCostQuarterTableValues);
  const correctionCost = phaseCostQuarters.reduce(
    (acc, { quarterCorectionCost }) => acc + (quarterCorectionCost || 0),
    0,
  );
  const totalCost = phaseCostQuarters.reduce((acc, { quarterCost }) => acc + quarterCost, 0);

  useEffect(() => {
    id && dispatch(SgwRequestActions.phaseCostQuarters.fetch(id));
  }, [dispatch, id]);

  return (
    <div className={C.retributionInfo}>
      <h5>{translate('sgw.overview.retribution.title')}</h5>
      <div className={C.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate('sgw.overview.retribution.quarter')}</TableCell>
              <TableCell />
              <TableCell align="right">{translate('sgw.overview.retribution.totalExcludingVAT')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {phaseCostQuarters.map(({ quarterCost, quarterIndex, quarterRange }) => (
              <TableRow>
                <TableCell>{translate('sgw.overview.retribution.quarterIndex', { index: quarterIndex })}</TableCell>
                <TableCell>{quarterRange}</TableCell>
                <TableCell>
                  <div className={C.costTableCell}>{`€ ${quarterCost.toFixed(2)}`}</div>
                </TableCell>
              </TableRow>
            ))}
            <Visible visible={!!correctionCost}>
              <TableRow>
                <TableCell>{translate('sgw.overview.retribution.correction')}</TableCell>
                <TableCell />
                <TableCell>
                  <div className={C.costTableCell}>{`€ ${correctionCost.toFixed(2)}`}</div>
                </TableCell>
              </TableRow>
            </Visible>
            <Visible visible={phaseCostQuarters.length > 1 || !!correctionCost}>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell>
                  <div className={C.costTableCell}>{`€ ${(totalCost + correctionCost).toFixed(2)}`}</div>
                </TableCell>
              </TableRow>
            </Visible>
          </TableBody>
        </Table>
        <p className={C.retributionInfoText}>
          {translateHtml(
            `sgw.overview.retribution.info${workType === WorkType.GroundWorksToPublicDomain ? 'UtilityWorks' : ''}`,
          )}
        </p>
      </div>
    </div>
  );
};
