import React, { FC, useEffect } from 'react';
import { translate } from '../../../translations/translate';
import { SgwWorkflowStep, WorkflowType } from '../../../types';
import { Required } from '../../common/forms/Required.component';
import { WorkflowTemplate } from '../../templates/WorkflowTemplate/WorkflowTemplate.component';
import { Fieldset } from '../../atoms';
import { useDispatch } from 'react-redux';
import { SgwRequestActions } from '../../../store/actions';
import { useParams } from 'react-router';
import { TypeInfo, PartnersInfo, PhasesInfo, RequestorInfo } from '../../molecules';
import { CheckboxWithLabelComponent } from '../../common/forms/CheckboxWithLabel.component';
import { useSgwRequestForm } from '../../../hooks';
import { FormProvider } from 'react-hook-form';
import { submitId } from '../../../common/utils/formUtils';
import { RequiredStar } from '../../common/forms/RequiredStar.component';
import { AttachmentsInfo } from '../../molecules/AttachmentsInfo/AttachmentsInfo.component';
import { RetributionInfo } from '../../molecules/RetributionInfo/RetributionInfo.component';
import { Visible } from '../../common/layout/Visible.component';
import { InvalidSgwRequestStartDateAlert } from '../../organisms';

export const OverviewPage: FC = () => {
  const step = SgwWorkflowStep.Overview;
  const dispatch = useDispatch();
  const { id } = useParams();
  const { formMethods, submitRequest, request } = useSgwRequestForm(step);

  useEffect(() => {
    id && dispatch(SgwRequestActions.fetch(id));
  }, [dispatch, id]);

  return (
    <WorkflowTemplate
      title={translate('sgw.overview.title')}
      step={SgwWorkflowStep.Overview}
      type={WorkflowType.Sgw}
      onNextLabel={translate('sgw.overview.submit')}
      nextDisabled={!formMethods.watch('readTermsAndConditions')}
    >
      <InvalidSgwRequestStartDateAlert />
      <Fieldset noBottomBorder>
        <RequestorInfo />
        <TypeInfo />
        <PartnersInfo />
        <PhasesInfo />
        <AttachmentsInfo />
        <Visible visible={!!request?.acl.sgwRetributionRequired}>
          <RetributionInfo workType={request?.workType} />
        </Visible>
        <FormProvider {...formMethods}>
          <form id={submitId(WorkflowType.Sgw, step)} onSubmit={submitRequest}>
            <CheckboxWithLabelComponent
              label={translate('sgw.overview.subscribeMailNotification')}
              {...formMethods.register('subscribeMailNotifications')}
            />
            <CheckboxWithLabelComponent
              {...formMethods.register('readTermsAndConditions')}
              label={
                <>
                  {translate('sgw.overview.readTermsAndConditions')}
                  <RequiredStar />
                </>
              }
            />
            <Required
              required={!formMethods.watch('readTermsAndConditions')}
              message={translate('sgw.overview.readTermsAndConditionsValidation')}
            />
          </form>
        </FormProvider>
      </Fieldset>
    </WorkflowTemplate>
  );
};
