import classNames from 'classnames';
import { useAtom } from 'jotai';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useGenericStyles, useScrollToTop } from '../../../hooks';
import { SgwRequestActions } from '../../../store/actions';
import { PhaseActions } from '../../../store/actions';
import { getRequest, selectPhasesAllIds, getPhases, selectRescheduling } from '../../../store/selectors/sgw';
import { translate } from '../../../translations/translate';
import { Visible } from '../../common/layout/Visible.component';
import { RequestorInfo, TypeInfo } from '../../molecules';
import { AttachmentsInfo } from '../../molecules/AttachmentsInfo/AttachmentsInfo.component';
import { RescheduleInfoTable } from '../../molecules/RescheduleInfo/RescheduleInfoTable.component';
import { PhasesDetail, ConflictsRequestInfo, DrawingRequestInfo, PartnerRequestInfo, Messages } from '../../organisms';
import { Button } from '@mui/material';
import { RescheduleInfo } from '../../molecules/RescheduleInfo/RescheduleInfo.component';
import { IPhaseRescheduling, TRouterWithId } from '../../../types';
import { RescheduleRequest } from '../../organisms/RescheduleRequest/RescheduleRequest.component';
import { atomWithHash } from 'jotai/utils';
import { RecurringPhaseTemplateDetail } from '../../organisms/RecurringPhaseTemplateDetail/RecurringPhaseTemplateDetail.component';
import { RetributionInfo } from '../../molecules/RetributionInfo/RetributionInfo.component';

export const replanModalAtom = atomWithHash<boolean>('replan', false, { replaceState: true });
export const MySgwRequestDetails: FC = () => {
  const G = useGenericStyles();
  const dispatch = useDispatch();
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const request = useSelector(getRequest(requestId));
  const allPhases = useSelector(selectPhasesAllIds);
  const [, setReplanModal] = useAtom(replanModalAtom);
  const reschedulings = useSelector(selectRescheduling);
  const phases = useSelector(getPhases(requestId!));
  const initialValues: IPhaseRescheduling[] = phases.map(({ id, dateFrom, dateUntil }) => ({
    phaseId: id,
    newDateFrom: dateFrom,
    newDateUntil: dateUntil,
  }));

  useEffect(() => {
    return () => {
      dispatch(
        SgwRequestActions.messages.set({
          acl: { messages_can_edit: false, messages_can_view: false },
          data: [],
        }),
      );
    };
    // eslint-disable-next-line
  }, []);

  useScrollToTop();

  useEffect(() => {
    if (requestId) {
      dispatch(SgwRequestActions.fetch(requestId));
      dispatch(PhaseActions.fetchList(requestId));
      dispatch(SgwRequestActions.messages.fetch({ requestId }));
      dispatch(SgwRequestActions.rescheduling.fetch({ requestId }));
    }
  }, [dispatch, requestId]);

  const onDownloadPermit = () => window.open(`${request?.permitDownloadUrl}`, '_blank');

  const showReschedulings = !!reschedulings && reschedulings.filter((r) => !r.appliedAt && !r.rejectedAt).length > 0;
  return (
    <Visible visible={!!request?.id && !!allPhases.length}>
      <section>
        <div className={classNames('row', G.flex.spaceBetween, G.spacing.marginBottom.MD)}>
          <div className="col-xs-12 col-md-6">
            <h3>{`${translate('general.request')} ${request?.referenceId}`}</h3>
          </div>
          <div className="col-xs-12 col-md-6 u-text-right">
            <Visible visible={!!request?.permitDownloadUrl}>
              <span className={G.spacing.marginRight.XS}>
                <Button
                  onClick={onDownloadPermit}
                  variant="contained"
                  startIcon={<i className="fa fa-cloud-download" />}
                >
                  {translate('myRequests.details.rescheduleRequest.downloadPermit')}
                </Button>
              </span>
            </Visible>
            <Visible visible={!!request?.acl?.createReschedulingRequest}>
              <span>
                <Button onClick={() => setReplanModal(true)} variant="contained">
                  {translate('myRequests.details.rescheduleRequest.rescheduleButton')}
                </Button>
              </span>
            </Visible>

            <Visible visible={showReschedulings}>
              <RescheduleInfo />
            </Visible>
          </div>
        </div>
        <Visible visible={!!initialValues.length}>
          <RescheduleRequest />
        </Visible>
        <div className={classNames('row', G.spacing.marginBottom.SM)}>
          <div className="col-xs-12 col-md-6">
            <Messages />
          </div>
          <div className="col-xs-12 col-md-6">
            <RequestorInfo disableStep />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <AttachmentsInfo disableStep />
          </div>
          <div className="col-xs-12 col-md-6">
            <TypeInfo disableStep />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Visible visible={!!request?.acl?.sgwRetributionRequired}>
              <RetributionInfo workType={request?.workType} />
            </Visible>
          </div>
          <div className="col-xs-12 col-md-6">
            <Visible visible={!!reschedulings}>
              <RescheduleInfoTable />
            </Visible>
          </div>
        </div>

        <PartnerRequestInfo />
        <PhasesDetail requestId={requestId!} />
        <RecurringPhaseTemplateDetail requestId={requestId!} />

        <DrawingRequestInfo />
        <ConflictsRequestInfo />
      </section>
    </Visible>
  );
};
